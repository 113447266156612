import React from "react";
import Layout from "../components/Layout";
import Helmet from "react-helmet";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import '../styles/404.css'

export default function Home() {

  return (
    <Layout>
        <Helmet>
            <title>404 - Page Not Found</title>
            <meta name="description" content="Page not found." />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div id="header">
				<Link to="/" title="Where Do You Go When Preparing For An Emergency?">
        <StaticImage
                src="../images/logo.png"
                placeholder="none"
                layout="fixed"
                width={225}
                alt="Dignity Health logo"
                formats={["auto", "webp", "avif"]}/>
				</Link>
			</div>
        <div id="notfound">
            <div className="notfound">
                <h1>404</h1>
                <h2>We're sorry, this page cannot be found.</h2>
                <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                <Link to="/">Back To Homepage</Link>
            </div>
        </div>
    </Layout>
  )
};